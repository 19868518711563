@tailwind base;
@tailwind components;
@tailwind utilities;

@import '../src/components/UserCardGridBackground.global.css';
@import '../src/components/Drawer.css';

body {
  /* controls the font smoothing, eg: Chrome displays fonts bolder than they actually are */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply text-gray-900;
}

a,
button,
[role='button'],
[role='none'],
input[type='radio'],
input[type='checkbox'],
select.select,
.focus-opacity {
  @apply transition-all;
  outline: 2px solid transparent !important;
}

a:focus,
button:focus,
[role='button']:focus,
[role='none']:focus,
input[type='radio']:focus,
input[type='checkbox']:focus,
select.select:focus,
.focus-opacity:focus {
  outline: 2px solid transparent !important;
}

a:focus-visible,
button:focus-visible,
[role='button']:focus-visible,
[role='none']:focus-visible,
input[type='radio']:focus-visible,
input[type='checkbox']:focus-visible,
input[type='checkbox']:focus-visible + div,
select.select:focus-visible {
  box-shadow: 0 0 0 2px #2142e7, 0 0 0 4px rgba(33, 66, 231, 0.1) !important;
  outline: 2px solid transparent !important;
}

.focus-opacity:focus-visible {
  opacity: 0.7;
  box-shadow: none !important;
}

.link {
  @apply transition-all underline underline-offset-2 hover:decoration-transparent rounded-lg;
}

.inner-border {
  @apply outline outline-1 outline-gray-900/[.12] outline-offset-[-1px];
}

._sidebar-nav {
  min-height: -webkit-fill-available;
}

select.select {
  @apply flex rounded-lg h-9 min-h-0 px-3 py-0 truncate text-sm text-gray-900 font-normal bg-gray-100 border border-gray-100 cursor-pointer !ring-0 focus:ring-0 focus:ring-offset-0 hover:bg-gray-200 hover:border-gray-200;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' class='h-full h-%5B18px%5D w-%5B18px%5D ml-3'%3E%3Cpath vector-effect='non-scaling-stroke' d='M6 9l6 6 6-6' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/svg%3E");
  background-size: 1.125rem 1.125rem;
  padding-right: 2.5rem;
  background-position: right 0.75rem center;
}

.modal {
  transition: opacity 0.25s ease;
}

body.modal-active {
  overflow-x: hidden;
  overflow-y: visible !important;
}

.tippy-box ._hs-inspector {
  color: initial !important;
}

.tippy-box {
  background-color: inherit;
  color: inherit;
}

._sidebar-nav ._user-block [data-tippy-root] {
  @apply !transform-none !left-2 !right-2 !bottom-[58px];
}

._hide-scrollbar::-webkit-scrollbar {
  display: none;
}

#arrow,
#arrow::before {
  position: absolute;
  width: 12px;
  height: 12px;
  background: inherit;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

#arcade-button-popover[data-placement^='top'] > #arrow {
  bottom: -6px;
}

/* Make clicks pass-through */
#nprogress {
  @apply relative z-[99] pointer-events-none;
}

/* Translate to tailwind + update colors + increase size */

#nprogress .bar {
  @apply fixed top-0 left-0 w-full h-[3px] transition-all z-10;
  background: linear-gradient(
    -45deg,
    #fdbc15,
    #ea12ad,
    #7000ff,
    #0500ff,
    #12a9ea
  );
  animation: gradient 2s ease infinite;
  background-size: 400% 400%;
}

@keyframes gradient {
  0% {
    background-position: 0% 100%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 100%;
  }
}

#nprogress .bar:before {
  content: '';
  z-index: -99;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    -45deg,
    #fdbc15,
    #ea12ad,
    #7000ff,
    #0500ff,
    #12a9ea
  );
  animation: gradient 2s ease infinite;
  background-size: 400% 400%;
  filter: blur(6px);
  opacity: 0.3;
  transition: all 0.3s;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.animate-scale {
  animation: scale 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.animate-fadeIn {
  animation: fadeIn 0.7s cubic-bezier(0, 0, 0.2, 1);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-pingSmall {
  animation: pingSmall 2s cubic-bezier(0, 0, 0.2, 1) infinite;
}

@keyframes pingSmall {
  50%,
  100% {
    @apply -inset-4 opacity-0;
  }
}

table._query-table {
  border-collapse: separate;
  border-spacing: 0 10px;
  margin-top: -10px; /* correct offset on first border spacing if desired */
}
table._query-table td {
  border-width: 1px;
  border-color: #f2f3f5;
  border-top-style: solid;
  border-right-style: none;
  border-left-style: none;
  border-bottom-style: solid;
  padding: 10px;
  text-align: center;
}
table._query-table tr.excluded-row td {
  color: #8b8b8b;
}
table._query-table td:first-child {
  border-left-style: solid;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
table._query-table td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

table._insights-query-table {
  @apply border-separate border-spacing-y-3;
}
table._insights-query-table td {
  @apply text-center;
}
table._insights-query-table tbody tr {
  @apply relative cursor-pointer;
}
table._insights-query-table tbody tr td {
  @apply p-3 transition-colors bg-white cursor-pointer first:rounded-l-lg last:rounded-r-lg;
}
table._insights-query-table tbody tr:hover td {
  @apply bg-gray-50;
}
table._insights-query-table tbody tr + tr:after {
  content: '';
  position: absolute;
  background-color: #e5e7eb;
  height: 1px;
  top: -6px;
  left: 12px;
  right: 12px;
}

/* Hide weird empty box in post-login state */
/* https://github.com/firebase/firebaseui-web/issues/121 */
#get-started .firebaseui-id-page-callback {
  height: 2px;
  overflow: hidden;
  display: block;
  min-height: 2px;
  box-shadow: none;
}

.firebase-emulator-warning {
  display: none;
}
.group-scoped:hover .group-scoped-hover\:h-3 {
  @apply h-3;
}
.group-scoped:hover .group-scoped-hover\:opacity-100 {
  @apply opacity-100;
}

/** CSS For the hotspot target */

@keyframes outerCircleAnimation {
  0% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  100% {
    transform: scale3d(2.3, 2.3, 2.3);
    opacity: 0;
  }
}

.hostpot-target .outer-circle {
  opacity: 0;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: outerCircleAnimation;
  animation-delay: 1.5s;
}

@keyframes innerCircleAnimation {
  0% {
    transform: scale3d(1, 1, 1);
  }
  18% {
    transform: scale3d(0.3, 0.3, 0.3);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

.hostpot-target .inner-circle {
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: innerCircleAnimation;
}

.ais-Highlight-highlighted {
  @apply bg-gray-200;
}

/* Hide the default webkit blue cross icon for used for reset/cancel search inputs */
input[type='search']::-webkit-search-cancel-button {
  display: none;
}

/* Tooltip theme */

.tippy-box[data-theme~='arcade-tooltip'] {
  @apply bg-gray-800 !rounded-lg !shadow-[0px_4px_10px_rgba(17,_24,_39,_0.08)] !text-xs !font-medium text-white !font-[Inter];
}

.tippy-box[data-theme~='arcade-tooltip'] .tippy-content {
  @apply p-[10px];
}
